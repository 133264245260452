import React, { useState, useRef } from 'react';
import LoadingFull from "../../components/Loading/LoadingFull";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from '@material-ui/core/Dialog';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import BGLogin from "assets/img/background-login-1.jpeg";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Chat from "@material-ui/icons/Chat";
import Build from "@material-ui/icons/Build";
import InputMask from 'react-input-mask';
import axios from "axios";
import estados from '../../services/estados.json';
import cidades_json from '../../services/cidades.json';
import bancos from '../../services/bancos.json';
import URL_BASE from '../../services/constantes.js';
import AllPagesPDFViewer from "./all-pages";


// core components
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import api from 'services/api';

const useStyles = makeStyles((theme) => ({
    root: {
      height: '100vh',
    },
    image: {
        backgroundImage: `url(${BGLogin})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
          theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    gridContainer: {
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '7%',
    },
    form: { 
        backgroundColor: "#EEE",
        maxWidth: '50%'
    },
    appBar: {
        position: 'relative',
        backgroundColor: '#00ACC1'
    },
    listDocsUl: {
        listStyle: 'none'
    },
    listDocsUlLi: {

    },
    containerModalSuccess: {
        margin: '0 auto',
        padding: '85px',
        fontSize: '15px'
    },
    alertFinanceiro: {
        backgroundColor: '#f2ff2d',
        padding: '15px',
        borderRadius: '5px',
        fontWeight: 900
    }
}));

const CssTextField = withStyles({
    root: {
      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          borderColor: '#00acc1',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00acc1',
        },
      },
      '& .MuiOutlinedInput-root.Mui-error': {
        '&:hover fieldset': {
          borderColor: '#f44336',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#f44336',
        },
      },
    },
})(TextField);

export default function SingUp () {

    const bottomRef = useRef(null);
    const [typePerson, setTypePerson] = useState("");
    const [displayTypePerson, setDislayTypePerson] = useState(null);
    const [focusNumber, setFocusNumber] = useState(false);
    const [indexTab, setIndexTab] = useState(null);
    const [aceitoTermosAfiliacao, setAceitoTermosAfiliacao] = useState(false);
    const [openTermosAfiliacao, setOpenTermosAfiliacao] = useState(false);
    const [aceitoTermosPolitica, setAceitoTermosPolitica] = useState(false);
    const [openTermosPolitica, setOpenTermosPolitica] = useState(false);
    const [openModalSuccess, setOpenModelSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [celular, setCelular] = useState("");
    const [name, setName] = useState("");
    const [lastname, setLastName] = useState("");
    const [cpf, setCpf] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [cep, setCep] = useState("");
    const [address, setAddress] = useState("");
    const [number, setNumber] = useState("");
    const [neighborhood, setNeighborhood] = useState("");
    const [complement, setComplement] = useState("");
    const [typeAccount, setTypeAccount] = useState("Corrente");
    const [bankAccount, setBankAccount] = useState();
    const [agencyAccount, setAgencyAccount] = useState("");
    const [agencyDigitAccount, setAgencyDigitAccount] = useState("");
    const [numberAccount, setNumberAccount] = useState("");
    const [numberDigitAccount, setNumberDigitAccount] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    
    const [citys, setCitys] = useState(cidades_json.filter(cidadej => cidadej.Estado == 1));
    const [banksAccount, setBanksAccount] = useState(bancos);

    const [erroTypePerson, setErroTypePerson] = useState(false);
    const [mensagemErroTypePerson, setMensagemErroTypePerson] = useState("");
    const [erroEmail, setErroEmail] = useState(false);
    const [mensagemErroEmail, setMensagemErroEmail] = useState("");
    const [erroSenha, setErroSenha] = useState(false);
    const [mensagemErroSenha, setMensagemErroSenha] = useState("");
    const [erroCelular, setErroCelular] = useState(false);
    const [mensagemErroCelular, setMensagemErroCelular] = useState("");
    const [erroName, setErroName] = useState(false);
    const [mensagemErroName, setMensagemErroName] = useState("");
    const [erroLastName, setErroLastName] = useState(false);
    const [mensagemErroLastName, setMensagemErroLastName] = useState("");
    const [erroCpf, setErroCpf] = useState(false);
    const [mensagemErroCpf, setMensagemErroCpf] = useState("");
    const [erroCompanyName, setErroCompanyName] = useState(false);
    const [mensagemErroCompanyName, setMensagemErroCompanyName] = useState("");
    const [erroCnpj, setErroCnpj] = useState(false);
    const [mensagemErroCnpj, setMensagemErroCnpj] = useState("");
    const [erroCep, setErroCep] = useState(false);
    const [mensagemErroCep, setMensagemErroCep] = useState("");
    const [erroAddress, setErroAddress] = useState(false);
    const [mensagemErroAddress, setMensagemErroAddress] = useState("");
    const [erroNumber, setErroNumber] = useState(false);
    const [mensagemErroNumber, setMensagemErroNumber] = useState("");
    const [erroNeighborhood, setErroNeighborhood] = useState(false);
    const [mensagemErroNeighborhood, setMensagemErroNeighborhood] = useState("");
    const [erroComplement, setErroComplement] = useState(false);
    const [mensagemErroComplement, setMensagemErroComplement] = useState("");
    const [erroState, setErroState] = useState(false);
    const [mensagemErroState, setMensagemErroState] = useState("");
    const [erroCity, setErroCity] = useState(false);
    const [mensagemErroCity, setMensagemErroCity] = useState("");
    const [erroAgencyAccount, setErroAgencyAccount] = useState(false);
    const [mensagemErroAgencyAccount, setMensagemErroAgencyAccount] = useState("");
    const [erroAgencyDigitAccount, setErroAgencyDigitAccount] = useState(false);
    const [mensagemErroAgencyDigitAccount, setMensagemErroAgencyDigitAccount] = useState("");
    const [erroNumberAccount, setErroNumberAccount] = useState(false);
    const [mensagemErroNumberAccount, setMensagemErroNumberAccount] = useState("");
    const [erroNumberDigitAccount, setErroNumberDigitAccount] = useState(false);
    const [mensagemErroNumberDigitAccount, setMensagemErroNumberDigitAccount] = useState("");

    //Neighborhood
    const classes = useStyles();

    const handleRadioChange = (event) => {
        setTypePerson(event.target.value);
        
        setDislayTypePerson((event.target.value == "PF"));

        setErroTypePerson(false); 
        setMensagemErroTypePerson("");
    };

    const handleTypeAccount = (event) => {
        setTypeAccount(event.target.value);
    };

    const handleChangeCEP = (event) => {
        let cep = event.target.value.replace(".", "").replace("-", "");
        setCep(cep);
        setErroCep(false); 
        setMensagemErroCep("");
        axios.get(`https://viacep.com.br/ws/${cep}/json/`).then(response => {
            if(!response.data.error) {
                let address = response.data;
                setAddress(address.logradouro);
                setNeighborhood(address.bairro);
                setErroAddress(false); setMensagemErroAddress("");
                setErroNeighborhood(false); setMensagemErroNeighborhood("");

                var estado = estados.filter(function (item) {
                    return (item.Sigla == address.uf) ? item.ID : '';
                });
                setState(estado[0].Sigla);
                //setErroState(false); setMensagemErroState("");
                
                var cidades_filtradas = cidades_json.filter(function (cidade) {
                    return cidade.Nome == address.localidade;
                });
                setCitys(cidades_filtradas);
                //setErroCity(false); setMensagemErroCity("");
                setFocusNumber(true);
            }
        }).catch((e) => {
            /*console.error(e);*/
        });
    };

    const handleChangeSelectUF = (event) => {        
        setState(event.target.value);
        
        var estado_selecionado = event.target.value;
        var estado = estados.filter(function (item) {
            return (item.Sigla == estado_selecionado) ? item.ID : '';
        });
        let id_estado = estado[0].ID;
        var cidades_filtradas = cidades_json.filter(function (cidade) {
            return cidade.Estado == id_estado;
        });
        setCitys(cidades_filtradas);
    };

    const handleChangeSelectCity = (event) => {        
        setCity(event.target.value);
    };

    const handleChangeBanksAccount = (event) => {        
        setBankAccount(event.target.value);
    };

    function handleClickForm (event, tab, submitForm = false)  {
        let erro = false;
        if(tab === 1) {
            erro = validadeTab1();
        } else if(tab === 2) {
            erro = validadeTab2();
        } else if(tab === 3) {
            erro = validadeTab3();
        }

        if(submitForm) {
            //submitFormFunction();
            setOpenTermosAfiliacao(true);
        }

        return erro;
    };

    function validadeTab1() {
        let erro = false;
        if(isNullOrEmpty(typePerson)) { setErroTypePerson(true); setMensagemErroTypePerson("Tipo pessoa"); erro = true; }
        if(isNullOrEmpty(email)) { setErroEmail(true); setMensagemErroEmail("E-mail é obrigatório"); erro = true; }
        if(isNullOrEmpty(senha)) { setErroSenha(true); setMensagemErroSenha("Senha é obrigatório"); erro = true; }
        if(isNullOrEmpty(celular)) { setErroCelular(true); setMensagemErroCelular("Celular é obrigatório"); erro = true; }
        if(displayTypePerson) {
            if(isNullOrEmpty(name)) { setErroName(true); setMensagemErroName("Nome é obrigatório"); erro = true; }
            if(isNullOrEmpty(lastname)) { setErroLastName(true); setMensagemErroLastName("Sobrenome é obrigatório"); erro = true; }
            if(isNullOrEmpty(cpf)) { setErroCpf(true); setMensagemErroCpf("CPF é obrigatório"); erro = true; }
        } else if(!displayTypePerson && displayTypePerson != null) {
            if(isNullOrEmpty(companyName)) { setErroCompanyName(true); setMensagemErroCompanyName("Razão Social é obrigatório"); erro = true; }
            if(isNullOrEmpty(cnpj)) { setErroCnpj(true); setMensagemErroCnpj("CNPJ é obrigatório"); erro = true; }
        }
        return erro;
    }

    function validadeTab2() {
        let erro = false;
        if(isNullOrEmpty(cep)) { setErroCep(true); setMensagemErroCep("CEP é obrigatório"); erro = true; }
        if(isNullOrEmpty(address)) { setErroAddress(true); setMensagemErroAddress("Endereço é obrigatório"); erro = true; }
        if(isNullOrEmpty(number)) { setErroNumber(true); setMensagemErroNumber("Número é obrigatório"); erro = true; }
        if(isNullOrEmpty(neighborhood)) { setErroNeighborhood(true); setMensagemErroNeighborhood("Bairro é obrigatório"); erro = true; }
        //if(isNullOrEmpty(state)) { setErroState(true); setMensagemErroState("Estado é obrigatório"); erro = true; }
        //if(isNullOrEmpty(city)) { setErroCity(true); setMensagemErroCity("Cidade é obrigatório"); erro = true; }
        return erro;
    }

    function validadeTab3() {
        let erro = false;
        if(isNullOrEmpty(agencyAccount)) { setErroAgencyAccount(true); setMensagemErroAgencyAccount("Agência é obrigatório"); erro = true; }
        //if(isNullOrEmpty(agencyDigitAccount)) { setErroAgencyDigitAccount(true); setMensagemErroAgencyDigitAccount("Dígito da agência é obrigatório"); erro = true; }
        if(isNullOrEmpty(numberAccount)) { setErroNumberAccount(true); setMensagemErroNumberAccount("Conta é obrigatório"); erro = true; }
        if(isNullOrEmpty(numberDigitAccount)) { setErroNumberDigitAccount(true); setMensagemErroNumberDigitAccount("Dígito da conta é obrigatório"); erro = true; }
        return erro;
    }

    function submitFormFunction() {
        setLoading(true);
        let objSubmit = {
            "Email": email,
            "Senha": senha,
            "Nome": name,
            "Sobrenome": lastname, 
            "Cpf": cpf,
            "Cnpj": cnpj,
            "NomeEmpresa": companyName,
            "TipoPessoa": typePerson,
            "Celular": celular,
            "Estado": state,
            "Cidade": city,
            "Bairro": neighborhood,
            "Numero": number,
            "Complemento": complement,
            "Cep": cep,
            "Endereco": address,
            "PerfiSistema": "AFILIADO",
            "Porcentagem": 20,
            "TipoDeConta": typeAccount,
            "Banco": bankAccount,
            "Agencia": agencyAccount,
            //"AgenciaDigito": agencyDigitAccount,
            "Conta": numberAccount,
            "ContaDigito": numberDigitAccount,
            "Status": "N"
        };

        axios.post(`${URL_BASE}/parceiro/Cadastrar`, objSubmit)
        .then(function(response) {
            console.log(response);
            setLoading(false);
            //alert("Cadastro efetuado! Enviamos uma e-mail para você com os próximos passos.");
            setOpenModelSuccess(true);
        }).catch(() => { setLoading(false); alert("Não conseguimos enviar, sua solicitação de cadastro, tente novamente!"); });
    }

    function isNullOrEmpty(value) {
        return value === null || value === "" ? true : false;
    }

    const handleCloseTermosAfiliacao = () => {
        setOpenTermosAfiliacao(false);
    };

    const handleChangeAceitoTermosAfiliacao = () => {
        setAceitoTermosAfiliacao(!aceitoTermosAfiliacao);
    };

    const handleChangeAceitoTermosPolitica = () => {
        setAceitoTermosPolitica(!aceitoTermosPolitica);
    };

    const handleClickNextTerm = () => {
        setOpenTermosAfiliacao(false);
        setOpenTermosPolitica(true);
    };

    const submitForm = () => {
        submitFormFunction();
    };

    const handleCloseModalSucesso = () => {
        document.location.reload(true);
    };

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    return (
        <>
            <LoadingFull display={loading} />
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={12} md={12} className={classes.image}>
                    <div className={classes.gridContainer}>
                        <form action="#" className={classes.form}>
                            <CustomTabs
                                headerColor="primary"
                                indexTab={indexTab}
                                tabs={[
                                {
                                    tabName: "Pessoal",
                                    tabIcon: Face,
                                    tabContent: (
                                        <>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <RadioGroup row aria-label="position" name="position" value={typePerson} onChange={handleRadioChange}>
                                                        
                                                        <GridItem xs={12} sm={12} md={6}>
                                                            <FormControlLabel
                                                                value="PF"
                                                                control={<Radio color="primary" disabled={false} />}
                                                                label="Física"
                                                            />
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={6}>
                                                            <FormControlLabel
                                                                value="PJ"
                                                                control={<Radio color="primary" disabled={false} />}
                                                                label="Jurídica"
                                                            />
                                                        </GridItem>
                                                    </RadioGroup>
                                                    <FormHelperText className={classes.errorMessage}>{mensagemErroTypePerson}</FormHelperText>
                                                </GridItem>
                                            </GridContainer>
                                            
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <CssTextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="email"
                                                        label="E-mail"
                                                        type="email"
                                                        id="email"
                                                        value={email}
                                                        onChange={(e) => {setEmail(e.target.value); setErroEmail(false); setMensagemErroEmail("");}}
                                                        error={erroEmail}
                                                        helperText={mensagemErroEmail}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <CssTextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="password"
                                                        label="Senha"
                                                        type="password"
                                                        id="password"
                                                        autoComplete="current-password"
                                                        value={senha}
                                                        onChange={(e) => {setSenha(e.target.value); setErroSenha(false); setMensagemErroSenha("");}}
                                                        error={erroSenha}
                                                        helperText={mensagemErroSenha}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                            
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <InputMask mask="(99) 9999-99999"
                                                        maskChar=" "
                                                        variant="outlined"
                                                        value={celular}
                                                        required
                                                        onChange={(e) => {setCelular(e.target.value); setErroCelular(false); setMensagemErroCelular("");}}
                                                        >
                                                        {(inputProps) => <TextField {...inputProps}
                                                            style={{ marginTop: '15px' }}
                                                            id="telefone"
                                                            label="Celular"
                                                            fullWidth
                                                            error={erroCelular}
                                                            helperText={mensagemErroCelular}
                                                        />}
                                                    </InputMask>
                                                </GridItem>
                                            </GridContainer>

                                            <div style={{ display: (displayTypePerson) ? 'block' : 'none' }}>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <CssTextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            name="name"
                                                            label="Nome"
                                                            type="text"
                                                            id="name"
                                                            value={name}
                                                            onChange={(e) => {setName(e.target.value); setErroName(false); setMensagemErroName("");}}
                                                            error={erroName}
                                                            helperText={mensagemErroName}
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <CssTextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            name="lastname"
                                                            label="Sobrenome"
                                                            type="text"
                                                            id="lastname"
                                                            value={lastname}
                                                            onChange={(e) => {setLastName(e.target.value); setErroLastName(false); setMensagemErroLastName("");}}
                                                            error={erroLastName}
                                                            helperText={mensagemErroLastName}
                                                        />
                                                    </GridItem>
                                                </GridContainer>

                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <InputMask mask="999.999.999-99"
                                                            maskChar=" "
                                                            variant="outlined"
                                                            required
                                                            value={cpf}
                                                            onChange={(e) => {setCpf(e.target.value); setErroCpf(false); setMensagemErroCpf("");}}
                                                            >
                                                            {(inputProps) => <TextField {...inputProps}
                                                                style={{ marginTop: '15px' }}
                                                                id="cpf"
                                                                label="CPF"
                                                                fullWidth
                                                                error={erroCpf}
                                                                helperText={mensagemErroCpf}
                                                            />}
                                                        </InputMask>
                                                    </GridItem>
                                                </GridContainer>
                                            </div>

                                            <div style={{ display: (!displayTypePerson && displayTypePerson != null) ? 'block' : 'none' }}>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <CssTextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            name="comanyname"
                                                            label="Razão Social"
                                                            type="text"
                                                            id="comanyname"
                                                            value={companyName}
                                                            onChange={(e) => {setCompanyName(e.target.value); setErroCompanyName(false); setMensagemErroCompanyName("");}}
                                                            error={erroCompanyName}
                                                            helperText={mensagemErroCompanyName}
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <InputMask mask="99.999.999/9999-99"
                                                            maskChar=" "
                                                            variant="outlined"                                                        
                                                            required
                                                            value={cnpj}
                                                            onChange={(e) => {setCnpj(e.target.value); setErroCnpj(false); setMensagemErroCnpj("");}}
                                                            >
                                                            {(inputProps) => <TextField {...inputProps}
                                                                style={{ marginTop: '15px' }}
                                                                id="cnpj"
                                                                label="CNPJ"
                                                                fullWidth
                                                                error={erroCnpj}
                                                                helperText={mensagemErroCnpj}
                                                            />}
                                                        </InputMask>
                                                    </GridItem>
                                                </GridContainer>
                                            </div>                
                                        </>
                                    )
                                },
                                {
                                    tabName: "Endereço",
                                    tabIcon: Chat,
                                    tabContent: (
                                        <>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <InputMask mask="99.999-999"
                                                        maskChar=" "
                                                        variant="outlined"
                                                        value={cep}
                                                        onChange={handleChangeCEP}
                                                        required
                                                        >
                                                        {(inputProps) => <TextField {...inputProps}
                                                            style={{ marginTop: '15px' }}
                                                            id="cep"
                                                            label="CEP"
                                                            fullWidth
                                                            error={erroCep}
                                                            helperText={mensagemErroCep}
                                                        />}
                                                    </InputMask>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={9}>
                                                    <CssTextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="address"
                                                        label="Rua, Avenida..."
                                                        type="text"
                                                        id="address"
                                                        value={address}
                                                        onChange={(e) => {setAddress(e.target.value); setErroAddress(false); setMensagemErroAddress("");}}
                                                        error={erroAddress}
                                                        helperText={mensagemErroAddress}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={3}>
                                                    <CssTextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        autoFocus={focusNumber}
                                                        name="number"
                                                        label="N°"
                                                        type="number"
                                                        id="number"
                                                        value={number}
                                                        onChange={(e) => {setNumber(e.target.value); setErroNumber(false); setMensagemErroNumber("");}}
                                                        error={erroNumber}
                                                        helperText={mensagemErroNumber}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <CssTextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="neighborhood"
                                                        label="Bairro"
                                                        type="text"
                                                        id="neighborhood"
                                                        value={neighborhood}
                                                        onChange={(e) => {setNeighborhood(e.target.value); setErroNeighborhood(false); setMensagemErroNeighborhood("");}}
                                                        error={erroNeighborhood}
                                                        helperText={mensagemErroNeighborhood}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <CssTextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        name="complement"
                                                        label="Complemento"
                                                        type="text"
                                                        id="complement"
                                                        value={complement}
                                                        onChange={(e) => {setComplement(e.target.value); setErroComplement(false); setMensagemErroComplement("");}}
                                                        error={erroComplement}
                                                        helperText={mensagemErroComplement}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <Select
                                                        variant="outlined"
                                                        native
                                                        fullWidth
                                                        id="uf"
                                                        value={state}
                                                        onChange={handleChangeSelectUF}
                                                        required
                                                    >
                                                        {estados.map((currentValue, index, arr) =>
                                                            <option key={index} value={currentValue.Sigla} >{currentValue.Sigla}</option>
                                                        )}
                                                    </Select>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <Select
                                                        variant="outlined"
                                                        native
                                                        fullWidth
                                                        id="city"
                                                        value={city}
                                                        onChange={handleChangeSelectCity}
                                                        required
                                                        >
                                                        {citys.map((currentValue, index, arr) => 
                                                            <option key={index} value={currentValue.Nome} >{currentValue.Nome}</option>
                                                        )}
                                                    </Select>
                                                </GridItem>
                                            </GridContainer>
                                        </>
                                    )
                                },
                                {
                                    tabName: "Financeiro",
                                    tabIcon: Build,
                                    tabContent: (
                                        <>
                                            <div className={classes.alertFinanceiro}>
                                                ATENÇÃO! Não será cobrado nenhum valor de sua conta. Esses dados são apenas para que receba sua comissão!
                                                <br/><br/>IMPORTANTE! A conta deverá ser equivalente ao cadastro.
                                                <br/>Cadastro PF = Conta PF
                                                <br/>Cadastro PJ = Conta PJ    
                                            </div>
                                            
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <RadioGroup row aria-label="position" name="position" value={typeAccount} onChange={handleTypeAccount}>
                                                        <GridItem xs={12} sm={12} md={6}>
                                                            <FormControlLabel
                                                                value="Corrente"
                                                                control={<Radio color="primary" disabled={false} />}
                                                                label="Corrente"
                                                            />
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={6}>
                                                            <FormControlLabel
                                                                value="Poupança"
                                                                control={<Radio color="primary" disabled={false} />}
                                                                label="Poupança"
                                                            />
                                                        </GridItem>
                                                    </RadioGroup>
                                                    <FormHelperText className={classes.errorMessage}>{""}</FormHelperText>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <Select
                                                        variant="outlined"
                                                        native
                                                        fullWidth
                                                        id="banco"
                                                        value={bankAccount}
                                                        onChange={handleChangeBanksAccount}
                                                        required
                                                    >
                                                        {banksAccount.map((currentValue, index, arr) =>
                                                            <option key={index} value={currentValue.value} >{currentValue.label}</option>
                                                        )}
                                                    </Select>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={4}>
                                                    <CssTextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="agencyAccount"
                                                        label="Agência"
                                                        type="number"
                                                        id="agencyAccount"
                                                        value={agencyAccount}
                                                        onChange={(e) => {setAgencyAccount(e.target.value); setErroAgencyAccount(false); setMensagemErroAgencyAccount("");}}
                                                        error={erroAgencyAccount}
                                                        helperText={mensagemErroAgencyAccount}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={5}>
                                                    <CssTextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="numberAccount"
                                                        label="Nº da Conta"
                                                        type="number"
                                                        id="numberAccount"
                                                        value={numberAccount}
                                                        onChange={(e) => {setNumberAccount(e.target.value); setErroNumberAccount(false); setMensagemErroNumberAccount("");}}
                                                        error={erroNumberAccount}
                                                        helperText={mensagemErroNumberAccount}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={3}>
                                                    <CssTextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="numberDigitAccount"
                                                        label="Conta Dígito"
                                                        type="number"
                                                        id="numberDigitAccount"
                                                        value={numberDigitAccount}
                                                        onChange={(e) => {setNumberDigitAccount(e.target.value); setErroNumberDigitAccount(false); setMensagemErroNumberDigitAccount("");}}
                                                        error={erroNumberDigitAccount}
                                                        helperText={mensagemErroNumberDigitAccount}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        </>
                                    )
                                }
                                ]}
                                navigationButtons={true}
                                form={true}
                                handleClick={handleClickForm}
                            />
                        </form>
                    </div>
                </Grid>
            </Grid>
            <Dialog 
                fullScreen 
                open={openTermosAfiliacao} 
                TransitionComponent={Transition} 
                maxWidth={'sm'}
                aria-labelledby="max-width-dialog-title"
                >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        
                        <IconButton color="inherit" onClick={handleCloseTermosAfiliacao} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <p style={{ margin: '0 auto' }}>TERMO DE AFILIAÇÃO</p>
                    </Toolbar>
                </AppBar>
                <AllPagesPDFViewer pdf={'https://condutorlegal.com.br/TERMO_DE_AFILIACAO_24-08-2022.pdf'} bottomRef={bottomRef} />
                {/*<AllPagesPDFViewer pdf={'https://condutorlegal.com.br/TERMO_DE_AFILIACAO_24-08-2022.pdf'} />*/}
                <FormControlLabel
                    checked={aceitoTermosAfiliacao}
                    control={<Checkbox color="primary"  />}
                    label="ACEITO OS TERMOS DE AFILIAÇÃO"
                    onChange={handleChangeAceitoTermosAfiliacao}
                    value={"SIM"}
                    required
                    style={{ margin: '0 auto', paddingBottom: '45px' }}
                />
                <Button 
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={!aceitoTermosAfiliacao}
                    onClick={handleClickNextTerm}
                    ref={bottomRef}
                    id="tt-btn"
                >
                    Continuar
                </Button>
            </Dialog>

            <Dialog 
                fullScreen 
                open={openTermosPolitica} 
                TransitionComponent={Transition} 
                maxWidth={'sm'}
                aria-labelledby="max-width-dialog-title"
                >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        
                        <IconButton color="inherit" onClick={handleCloseTermosAfiliacao} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <p style={{ margin: '0 auto' }}>TERMOS DE USO, CONDIÇÕES E POLÍTICAS</p>
                    </Toolbar>
                </AppBar>
                <AllPagesPDFViewer pdf={'https://condutorlegal.com.br/TERMOS_DE_USO_E_CONDICOES_E_POLITICAS_20-08-22.pdf'} />
                <FormControlLabel
                    checked={aceitoTermosPolitica}
                    control={<Checkbox color="primary"  />}
                    label="ACEITO OS TERMOS DE USO, CONDIÇÕES E POLÍTICAS"
                    onChange={handleChangeAceitoTermosPolitica}
                    value={"SIM"}
                    required
                    style={{ margin: '0 auto', paddingBottom: '45px' }}
                />
                <Button 
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={!aceitoTermosPolitica}
                    onClick={submitForm}
                >
                    Enviar cadatro
                </Button>
            </Dialog>

            <Dialog 
                fullScreen 
                open={openModalSuccess} 
                TransitionComponent={Transition} 
                maxWidth={'sm'}
                aria-labelledby="max-width-dialog-title"
                >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        
                        <IconButton color="inherit" onClick={handleCloseModalSucesso} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <p style={{ margin: '0 auto' }}>INSTRUÇÕES</p>
                    </Toolbar>
                </AppBar>
                <div className={classes.containerModalSuccess}>
                    { typePerson === "PJ" ? <p>{`Olá, ${companyName},`}</p> : <p>{`Olá, ${name},`}</p> }
                    <p>{`Olá, ${name},`}</p>
                    <p>Confirmamos seu cadastramento em nossa plataforma para Credenciamento como Afiliado e estamos muito felizes com nossa futura parceria!</p>
                    <p>Para prosseguirmos, será necessário que nos envie através deste e-mail, os documentos abaixo. Observe</p>
                    
                    <ul className={classes.listDocsUl} style={ {display: (typePerson === "PJ") ? 'block' : 'none'} }>
                        <li className={classes.listDocsUlLi}><strong>→ Certificado MEI ou Contrato Social</strong></li>
                        <li className={classes.listDocsUlLi}><strong>→ Cartão CNPJ</strong></li>
                        <li className={classes.listDocsUlLi}><strong>→ Documento de Identidade (Frente e Verso)</strong></li>
                        <li className={classes.listDocsUlLi}><strong>→ CPF</strong></li>
                        <li className={classes.listDocsUlLi}><strong>→ Comprovante de Endereço (Últimos 3 meses - O comprovante deverá ser uma conta de consumo (Água, Luz, Telefone ou Internet) ou Declaração da Associação de Moradores.</strong></li>
                    </ul>

                    <ul className={classes.listDocsUl} style={ {display: (typePerson === "PF") ? 'block' : 'none'} }>
                        <li className={classes.listDocsUlLi}><strong>→ Documento de Identidade (Frente e Verso)</strong></li>
                        <li className={classes.listDocsUlLi}><strong>→ CPF</strong></li>
                        <li className={classes.listDocsUlLi}><strong>→ Comprovante de Residência dos últimos 3 meses. O comprovante deverá ser uma conta de consumo (Água, Luz, Telefone ou Internet) ou Declaração da Associação de Moradores.</strong></li>
                        <li className={classes.listDocsUlLi}><strong>→ Documento que comprove o número de inscrição no PIS.</strong></li>
                    </ul>
                    <p><u><strong>É muito importante que as imagens anexadas estejam nítidas.</strong></u></p>
                    <p>O envio da documentação deverá ocorrer no prazo de até 3 dias. Após esse período, sua solicitação de credenciamento poderá ser cancelada e deverá iniciar um novo processo, caso deseje se credenciar.</p>
                    <p>Após o recebimento da documentação, retornaremos com a resposta sobre seu credenciamento em até 7 dias.</p>
                    <p>Caso tenha alguma dúvida ou dificuldade, nos informe através do e-mail: <a href="mailto:suporteafiliados@condutorlegal.com.br"><u>suporteafiliados@condutorlegal.com.br</u></a>, <u>deixando seu número de contato com DDD</u>. Retornaremos seu contato por e-mail ou whatsapp em até 48h.</p>
                    <p>Nos falaremos novamente em breve!</p>
                    <p>Equipe Condutor Legal</p>
                </div>
            </Dialog>

        </>
    );
}