import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import BGLogin from "assets/img/background-login-1.jpeg";
import api from 'services/api';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link color="inherit" href="https://condutorlegal.com.br">
        Condutor Legal.
      </Link>{' '}
      {'© Todos os direitos reservados '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#00acc1',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#00acc1',
      },
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      '&:hover fieldset': {
        borderColor: '#f44336',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#f44336',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${BGLogin})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#00acc1",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#00acc1",
    '&:hover': {
        backgroundColor: "#00acc1",
    },
    input: {
        '&:focus': {
            borderColor: "red",
        }
    }
  },
  singup: {
    color: "#FFFFFF",
    margin: theme.spacing(0, 0, 2),
    backgroundColor: "#38e007",
    '&:hover': {
        backgroundColor: "#38e007",
    },
    input: {
        '&:focus': {
            borderColor: "red",
        }
    }
  }
}));

export default function SignInSide(props) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [erroEmail, setErroEmail] = useState(false);
  const [mensagemErroEmail, setMensagemErroEmail] = useState("");
  const [erroSenha, setErroSenha] = useState(false);
  const [mensagemErroSenha, setMensagemErroSenha] = useState("");

  let id = localStorage.getItem("CDLAfiliadoId");
  if(id == "" || id == null) {
    
  } else {
    props.history.push('/admin/dashboard');
  }

  async function handleSubmitForm(e) {
    e.preventDefault();
    let errorForm = false;
    if(email == "") {
      setErroEmail(true);
      setMensagemErroEmail("E-mail obrigatório");
      errorForm = true;
    }
    if(senha == "") {
      setErroSenha(true);
      setMensagemErroSenha("Senha obrigatório");
      errorForm = true;
    }
    if (errorForm) {
      return false;
    }

    let formdata = new FormData();
    formdata.append('function', 'login');
    formdata.append('email', email);
    formdata.append('senha', senha);

    api.post('parceiro.php', formdata)
    .then(function (response) {
      //console.log(response);
      if (response.data.response == 'error-email') {
        setErroEmail(true);
        setMensagemErroEmail("E-mail não confere");
        return false;
      }
      if (response.data.response == 'error-pwd') {
        setErroSenha(true);
        setMensagemErroSenha("Senha incorreta");
        return false;
      }
      if (response.data.response == 'success') {
        let afiliado = response.data.afiliado;
        localStorage.setItem('CDLAfiliadoId', afiliado.Id);
        localStorage.setItem('CDLAfiliadoEmail', afiliado.Email);
        localStorage.setItem('CDLAfiliadoPerfilSistema', afiliado.PerfilSistema);
        localStorage.setItem('CDLAfiliadoLink', afiliado.LinkParceiro);
        props.history.push('/admin/dashboard');
      }
      
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .finally(function () {
      // always executed
    });
  }

  function handleClickSingup(e) {
    e.preventDefault();
    props.history.push('/cadastrar');
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <form className={classes.form} onSubmit={handleSubmitForm} noValidate>
            <CssTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              className={classes.input}
              value={email}
              onChange={(e) => {setEmail(e.target.value); setErroEmail(false); setMensagemErroEmail("");}}
              error={erroEmail}
              helperText={mensagemErroEmail}
            />
            <CssTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              value={senha}
              onChange={(e) => {setSenha(e.target.value); setErroSenha(false); setMensagemErroSenha("");}}
              error={erroSenha}
              helperText={mensagemErroSenha}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Acessar
            </Button>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="success"
              className={classes.singup}
              onClick={handleClickSingup}
            >
              Cadastrar
            </Button>
            <Grid container>
              <Grid item xs>
               {/*  <Link href="#" variant="body2">
                  Forgot password?
                </Link> */}
              </Grid>
              <Grid item>
                {/* <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link> */}
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}