import React from "react";
import { Redirect } from "react-router-dom";

const checaLogin = (props) => {
    let email = localStorage.getItem('CDLAfiliadoEmail');
    if(email == "" || email == null) {
        console.log("passou aqui", props);
        props.history.push('/login');
    }
};

export default checaLogin;